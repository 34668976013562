/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceSpecV1RolloutDto
 */
export interface ServiceSpecV1RolloutDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceSpecV1RolloutDto
     */
    strategy: ServiceSpecV1RolloutDtoStrategyEnum;
}


/**
 * @export
 */
export const ServiceSpecV1RolloutDtoStrategyEnum = {
    RollingUpdate: 'ROLLING_UPDATE',
    RedBlack: 'RED_BLACK',
    Recreate: 'RECREATE'
} as const;
export type ServiceSpecV1RolloutDtoStrategyEnum = typeof ServiceSpecV1RolloutDtoStrategyEnum[keyof typeof ServiceSpecV1RolloutDtoStrategyEnum];


/**
 * Check if a given object implements the ServiceSpecV1RolloutDto interface.
 */
export function instanceOfServiceSpecV1RolloutDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "strategy" in value;

    return isInstance;
}

export function ServiceSpecV1RolloutDtoFromJSON(json: any): ServiceSpecV1RolloutDto {
    return ServiceSpecV1RolloutDtoFromJSONTyped(json, false);
}

export function ServiceSpecV1RolloutDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceSpecV1RolloutDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'strategy': json['strategy'],
    };
}

export function ServiceSpecV1RolloutDtoToJSON(value?: ServiceSpecV1RolloutDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'strategy': value.strategy,
    };
}

