/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstanceStatusV1Dto
 */
export interface InstanceStatusV1Dto {
    /**
     * 
     * @type {string}
     * @memberof InstanceStatusV1Dto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceStatusV1Dto
     */
    status?: InstanceStatusV1DtoStatusEnum;
}


/**
 * @export
 */
export const InstanceStatusV1DtoStatusEnum = {
    Pending: 'PENDING',
    Healthy: 'HEALTHY',
    Failing: 'FAILING',
    Deleted: 'DELETED',
    Unknown: 'UNKNOWN'
} as const;
export type InstanceStatusV1DtoStatusEnum = typeof InstanceStatusV1DtoStatusEnum[keyof typeof InstanceStatusV1DtoStatusEnum];


/**
 * Check if a given object implements the InstanceStatusV1Dto interface.
 */
export function instanceOfInstanceStatusV1Dto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InstanceStatusV1DtoFromJSON(json: any): InstanceStatusV1Dto {
    return InstanceStatusV1DtoFromJSONTyped(json, false);
}

export function InstanceStatusV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceStatusV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function InstanceStatusV1DtoToJSON(value?: InstanceStatusV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'status': value.status,
    };
}

