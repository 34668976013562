/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RevisionTemplateSpecV1ObservabilityMetricsDto } from './RevisionTemplateSpecV1ObservabilityMetricsDto';
import {
    RevisionTemplateSpecV1ObservabilityMetricsDtoFromJSON,
    RevisionTemplateSpecV1ObservabilityMetricsDtoFromJSONTyped,
    RevisionTemplateSpecV1ObservabilityMetricsDtoToJSON,
} from './RevisionTemplateSpecV1ObservabilityMetricsDto';

/**
 * 
 * @export
 * @interface RevisionTemplateSpecV1ObservabilityDto
 */
export interface RevisionTemplateSpecV1ObservabilityDto {
    /**
     * 
     * @type {RevisionTemplateSpecV1ObservabilityMetricsDto}
     * @memberof RevisionTemplateSpecV1ObservabilityDto
     */
    metrics: RevisionTemplateSpecV1ObservabilityMetricsDto;
}

/**
 * Check if a given object implements the RevisionTemplateSpecV1ObservabilityDto interface.
 */
export function instanceOfRevisionTemplateSpecV1ObservabilityDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metrics" in value;

    return isInstance;
}

export function RevisionTemplateSpecV1ObservabilityDtoFromJSON(json: any): RevisionTemplateSpecV1ObservabilityDto {
    return RevisionTemplateSpecV1ObservabilityDtoFromJSONTyped(json, false);
}

export function RevisionTemplateSpecV1ObservabilityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionTemplateSpecV1ObservabilityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metrics': RevisionTemplateSpecV1ObservabilityMetricsDtoFromJSON(json['metrics']),
    };
}

export function RevisionTemplateSpecV1ObservabilityDtoToJSON(value?: RevisionTemplateSpecV1ObservabilityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metrics': RevisionTemplateSpecV1ObservabilityMetricsDtoToJSON(value.metrics),
    };
}

