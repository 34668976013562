/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Robot account definition
 * @export
 * @interface HarborProjectRobotAccountV1Dto
 */
export interface HarborProjectRobotAccountV1Dto {
    /**
     * The name of the robot. This will be prefixed by the instance and project when the robot is created. 
     * For example, if you set the name to be ci-read in the test project on the prod instance of Harbor, 
     * the full name of the robot will be 'prod-harbor-robot@test+ci-read'
     * 
     * @type {string}
     * @memberof HarborProjectRobotAccountV1Dto
     */
    name: string;
    /**
     * A list of resources and actions the robot will have access to
     * @type {Set<string>}
     * @memberof HarborProjectRobotAccountV1Dto
     */
    permissions: Set<HarborProjectRobotAccountV1DtoPermissionsEnum>;
    /**
     * The path to the secret in MSS that contains the robot's credentials
     * @type {string}
     * @memberof HarborProjectRobotAccountV1Dto
     */
    readonly mssSecretPath?: string;
}


/**
 * @export
 */
export const HarborProjectRobotAccountV1DtoPermissionsEnum = {
    RepositoryList: 'REPOSITORY_LIST',
    RepositoryPush: 'REPOSITORY_PUSH',
    RepositoryDelete: 'REPOSITORY_DELETE',
    RepositoryPull: 'REPOSITORY_PULL',
    ArtifactRead: 'ARTIFACT_READ',
    ArtifactList: 'ARTIFACT_LIST',
    ArtifactDelete: 'ARTIFACT_DELETE',
    ArtifactLabelCreate: 'ARTIFACT_LABEL_CREATE',
    ArtifactLabelDelete: 'ARTIFACT_LABEL_DELETE',
    ArtifactAdditionRead: 'ARTIFACT_ADDITION_READ',
    TagCreate: 'TAG_CREATE',
    TagList: 'TAG_LIST',
    TagDelete: 'TAG_DELETE',
    ScanStart: 'SCAN_START',
    ScanStop: 'SCAN_STOP'
} as const;
export type HarborProjectRobotAccountV1DtoPermissionsEnum = typeof HarborProjectRobotAccountV1DtoPermissionsEnum[keyof typeof HarborProjectRobotAccountV1DtoPermissionsEnum];


/**
 * Check if a given object implements the HarborProjectRobotAccountV1Dto interface.
 */
export function instanceOfHarborProjectRobotAccountV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function HarborProjectRobotAccountV1DtoFromJSON(json: any): HarborProjectRobotAccountV1Dto {
    return HarborProjectRobotAccountV1DtoFromJSONTyped(json, false);
}

export function HarborProjectRobotAccountV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborProjectRobotAccountV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'permissions': json['permissions'],
        'mssSecretPath': !exists(json, 'mssSecretPath') ? undefined : json['mssSecretPath'],
    };
}

export function HarborProjectRobotAccountV1DtoToJSON(value?: HarborProjectRobotAccountV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'permissions': Array.from(value.permissions as Set<any>),
    };
}

