/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecretGroupStatusV1Dto
 */
export interface SecretGroupStatusV1Dto {
    /**
     * 
     * @type {number}
     * @memberof SecretGroupStatusV1Dto
     */
    currentVersion: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SecretGroupStatusV1Dto
     */
    availableVersions: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof SecretGroupStatusV1Dto
     */
    platformSecret: boolean;
}

/**
 * Check if a given object implements the SecretGroupStatusV1Dto interface.
 */
export function instanceOfSecretGroupStatusV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentVersion" in value;
    isInstance = isInstance && "availableVersions" in value;
    isInstance = isInstance && "platformSecret" in value;

    return isInstance;
}

export function SecretGroupStatusV1DtoFromJSON(json: any): SecretGroupStatusV1Dto {
    return SecretGroupStatusV1DtoFromJSONTyped(json, false);
}

export function SecretGroupStatusV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecretGroupStatusV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentVersion': json['currentVersion'],
        'availableVersions': json['availableVersions'],
        'platformSecret': json['platformSecret'],
    };
}

export function SecretGroupStatusV1DtoToJSON(value?: SecretGroupStatusV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentVersion': value.currentVersion,
        'availableVersions': value.availableVersions,
        'platformSecret': value.platformSecret,
    };
}

