/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ScalingTypeSpecV1Dto = {
    CpuBased: 'CPU_BASED',
    Fixed: 'FIXED'
} as const;
export type ScalingTypeSpecV1Dto = typeof ScalingTypeSpecV1Dto[keyof typeof ScalingTypeSpecV1Dto];


export function ScalingTypeSpecV1DtoFromJSON(json: any): ScalingTypeSpecV1Dto {
    return ScalingTypeSpecV1DtoFromJSONTyped(json, false);
}

export function ScalingTypeSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScalingTypeSpecV1Dto {
    return json as ScalingTypeSpecV1Dto;
}

export function ScalingTypeSpecV1DtoToJSON(value?: ScalingTypeSpecV1Dto | null): any {
    return value as any;
}

