/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HarborProjectWebhookTargetV1Dto } from './HarborProjectWebhookTargetV1Dto';
import {
    HarborProjectWebhookTargetV1DtoFromJSON,
    HarborProjectWebhookTargetV1DtoFromJSONTyped,
    HarborProjectWebhookTargetV1DtoToJSON,
} from './HarborProjectWebhookTargetV1Dto';

/**
 * Webhook definition
 * @export
 * @interface HarborProjectWebhookV1Dto
 */
export interface HarborProjectWebhookV1Dto {
    /**
     * The name of the webhook
     * @type {string}
     * @memberof HarborProjectWebhookV1Dto
     */
    name: string;
    /**
     * A short description of what the hook is for
     * @type {string}
     * @memberof HarborProjectWebhookV1Dto
     */
    description?: string;
    /**
     * Is the webhook enabled
     * @type {boolean}
     * @memberof HarborProjectWebhookV1Dto
     */
    enabled?: boolean;
    /**
     * The types of Harbor events that will trigger the webhook
     * @type {Set<string>}
     * @memberof HarborProjectWebhookV1Dto
     */
    eventTypes: Set<HarborProjectWebhookV1DtoEventTypesEnum>;
    /**
     * 
     * @type {HarborProjectWebhookTargetV1Dto}
     * @memberof HarborProjectWebhookV1Dto
     */
    target: HarborProjectWebhookTargetV1Dto;
}


/**
 * @export
 */
export const HarborProjectWebhookV1DtoEventTypesEnum = {
    DeleteArtifact: 'DELETE_ARTIFACT',
    PullArtifact: 'PULL_ARTIFACT',
    PushArtifact: 'PUSH_ARTIFACT',
    DeleteChart: 'DELETE_CHART',
    DownloadChart: 'DOWNLOAD_CHART',
    UploadChart: 'UPLOAD_CHART',
    QuotaExceed: 'QUOTA_EXCEED',
    QuotaWarning: 'QUOTA_WARNING',
    Replication: 'REPLICATION',
    ScanningFailed: 'SCANNING_FAILED',
    ScanningCompleted: 'SCANNING_COMPLETED',
    ScanningStopped: 'SCANNING_STOPPED',
    TagRetention: 'TAG_RETENTION'
} as const;
export type HarborProjectWebhookV1DtoEventTypesEnum = typeof HarborProjectWebhookV1DtoEventTypesEnum[keyof typeof HarborProjectWebhookV1DtoEventTypesEnum];


/**
 * Check if a given object implements the HarborProjectWebhookV1Dto interface.
 */
export function instanceOfHarborProjectWebhookV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "eventTypes" in value;
    isInstance = isInstance && "target" in value;

    return isInstance;
}

export function HarborProjectWebhookV1DtoFromJSON(json: any): HarborProjectWebhookV1Dto {
    return HarborProjectWebhookV1DtoFromJSONTyped(json, false);
}

export function HarborProjectWebhookV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborProjectWebhookV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'eventTypes': json['eventTypes'],
        'target': HarborProjectWebhookTargetV1DtoFromJSON(json['target']),
    };
}

export function HarborProjectWebhookV1DtoToJSON(value?: HarborProjectWebhookV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'enabled': value.enabled,
        'eventTypes': Array.from(value.eventTypes as Set<any>),
        'target': HarborProjectWebhookTargetV1DtoToJSON(value.target),
    };
}

