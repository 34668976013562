/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ScalingTypeSpecV1Dto } from './ScalingTypeSpecV1Dto';
import {
    ScalingTypeSpecV1DtoFromJSON,
    ScalingTypeSpecV1DtoFromJSONTyped,
    ScalingTypeSpecV1DtoToJSON,
} from './ScalingTypeSpecV1Dto';

/**
 * 
 * @export
 * @interface FixedScalingSpecV1Dto
 */
export interface FixedScalingSpecV1Dto {
    /**
     * 
     * @type {ScalingTypeSpecV1Dto}
     * @memberof FixedScalingSpecV1Dto
     */
    scalingType: ScalingTypeSpecV1Dto;
    /**
     * 
     * @type {number}
     * @memberof FixedScalingSpecV1Dto
     */
    count: number;
}

/**
 * Check if a given object implements the FixedScalingSpecV1Dto interface.
 */
export function instanceOfFixedScalingSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scalingType" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function FixedScalingSpecV1DtoFromJSON(json: any): FixedScalingSpecV1Dto {
    return FixedScalingSpecV1DtoFromJSONTyped(json, false);
}

export function FixedScalingSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FixedScalingSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scalingType': ScalingTypeSpecV1DtoFromJSON(json['scalingType']),
        'count': json['count'],
    };
}

export function FixedScalingSpecV1DtoToJSON(value?: FixedScalingSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scalingType': ScalingTypeSpecV1DtoToJSON(value.scalingType),
        'count': value.count,
    };
}

