/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActivityLogV1MetadataDto
 */
export interface ActivityLogV1MetadataDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityLogV1MetadataDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLogV1MetadataDto
     */
    application?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLogV1MetadataDto
     */
    environment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ActivityLogV1MetadataDto
     */
    readonly firstOccurrenceTimestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActivityLogV1MetadataDto
     */
    readonly lastModificationTimestamp?: Date;
}

/**
 * Check if a given object implements the ActivityLogV1MetadataDto interface.
 */
export function instanceOfActivityLogV1MetadataDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ActivityLogV1MetadataDtoFromJSON(json: any): ActivityLogV1MetadataDto {
    return ActivityLogV1MetadataDtoFromJSONTyped(json, false);
}

export function ActivityLogV1MetadataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityLogV1MetadataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'application': !exists(json, 'application') ? undefined : json['application'],
        'environment': !exists(json, 'environment') ? undefined : json['environment'],
        'firstOccurrenceTimestamp': !exists(json, 'firstOccurrenceTimestamp') ? undefined : (new Date(json['firstOccurrenceTimestamp'])),
        'lastModificationTimestamp': !exists(json, 'lastModificationTimestamp') ? undefined : (new Date(json['lastModificationTimestamp'])),
    };
}

export function ActivityLogV1MetadataDtoToJSON(value?: ActivityLogV1MetadataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'application': value.application,
        'environment': value.environment,
    };
}

