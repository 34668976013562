/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { APIVersionV1Dto } from './APIVersionV1Dto';
import {
    APIVersionV1DtoFromJSON,
    APIVersionV1DtoFromJSONTyped,
    APIVersionV1DtoToJSON,
} from './APIVersionV1Dto';
import type { ActivityLogSpecV1Dto } from './ActivityLogSpecV1Dto';
import {
    ActivityLogSpecV1DtoFromJSON,
    ActivityLogSpecV1DtoFromJSONTyped,
    ActivityLogSpecV1DtoToJSON,
} from './ActivityLogSpecV1Dto';
import type { ActivityLogV1MetadataDto } from './ActivityLogV1MetadataDto';
import {
    ActivityLogV1MetadataDtoFromJSON,
    ActivityLogV1MetadataDtoFromJSONTyped,
    ActivityLogV1MetadataDtoToJSON,
} from './ActivityLogV1MetadataDto';

/**
 * 
 * @export
 * @interface ActivityLogV1Dto
 */
export interface ActivityLogV1Dto {
    /**
     * 
     * @type {APIVersionV1Dto}
     * @memberof ActivityLogV1Dto
     */
    apiVersion: APIVersionV1Dto;
    /**
     * 
     * @type {string}
     * @memberof ActivityLogV1Dto
     */
    kind: ActivityLogV1DtoKindEnum;
    /**
     * 
     * @type {ActivityLogV1MetadataDto}
     * @memberof ActivityLogV1Dto
     */
    metadata: ActivityLogV1MetadataDto;
    /**
     * 
     * @type {ActivityLogSpecV1Dto}
     * @memberof ActivityLogV1Dto
     */
    spec: ActivityLogSpecV1Dto;
    /**
     * 
     * @type {object}
     * @memberof ActivityLogV1Dto
     */
    readonly status?: object;
}


/**
 * @export
 */
export const ActivityLogV1DtoKindEnum = {
    ActivityLog: 'ActivityLog'
} as const;
export type ActivityLogV1DtoKindEnum = typeof ActivityLogV1DtoKindEnum[keyof typeof ActivityLogV1DtoKindEnum];


/**
 * Check if a given object implements the ActivityLogV1Dto interface.
 */
export function instanceOfActivityLogV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apiVersion" in value;
    isInstance = isInstance && "kind" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "spec" in value;

    return isInstance;
}

export function ActivityLogV1DtoFromJSON(json: any): ActivityLogV1Dto {
    return ActivityLogV1DtoFromJSONTyped(json, false);
}

export function ActivityLogV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityLogV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiVersion': APIVersionV1DtoFromJSON(json['apiVersion']),
        'kind': json['kind'],
        'metadata': ActivityLogV1MetadataDtoFromJSON(json['metadata']),
        'spec': ActivityLogSpecV1DtoFromJSON(json['spec']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ActivityLogV1DtoToJSON(value?: ActivityLogV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiVersion': APIVersionV1DtoToJSON(value.apiVersion),
        'kind': value.kind,
        'metadata': ActivityLogV1MetadataDtoToJSON(value.metadata),
        'spec': ActivityLogSpecV1DtoToJSON(value.spec),
    };
}

