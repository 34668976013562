/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PipelineStatusV1StepsInnerMessageDto } from './PipelineStatusV1StepsInnerMessageDto';
import {
    PipelineStatusV1StepsInnerMessageDtoFromJSON,
    PipelineStatusV1StepsInnerMessageDtoFromJSONTyped,
    PipelineStatusV1StepsInnerMessageDtoToJSON,
} from './PipelineStatusV1StepsInnerMessageDto';

/**
 * 
 * @export
 * @interface PipelineStatusV1StepsInnerDto
 */
export interface PipelineStatusV1StepsInnerDto {
    /**
     * 
     * @type {PipelineStatusV1StepsInnerMessageDto}
     * @memberof PipelineStatusV1StepsInnerDto
     */
    message?: PipelineStatusV1StepsInnerMessageDto;
    /**
     * 
     * @type {string}
     * @memberof PipelineStatusV1StepsInnerDto
     */
    code: string;
    /**
     * 
     * @type {Date}
     * @memberof PipelineStatusV1StepsInnerDto
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PipelineStatusV1StepsInnerDto
     */
    endTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof PipelineStatusV1StepsInnerDto
     */
    durationMillis?: number;
    /**
     * 
     * @type {string}
     * @memberof PipelineStatusV1StepsInnerDto
     */
    state: PipelineStatusV1StepsInnerDtoStateEnum;
}


/**
 * @export
 */
export const PipelineStatusV1StepsInnerDtoStateEnum = {
    Queued: 'QUEUED',
    Running: 'RUNNING',
    Pending: 'PENDING',
    Failed: 'FAILED',
    Completed: 'COMPLETED',
    Canceled: 'CANCELED'
} as const;
export type PipelineStatusV1StepsInnerDtoStateEnum = typeof PipelineStatusV1StepsInnerDtoStateEnum[keyof typeof PipelineStatusV1StepsInnerDtoStateEnum];


/**
 * Check if a given object implements the PipelineStatusV1StepsInnerDto interface.
 */
export function instanceOfPipelineStatusV1StepsInnerDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function PipelineStatusV1StepsInnerDtoFromJSON(json: any): PipelineStatusV1StepsInnerDto {
    return PipelineStatusV1StepsInnerDtoFromJSONTyped(json, false);
}

export function PipelineStatusV1StepsInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineStatusV1StepsInnerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : PipelineStatusV1StepsInnerMessageDtoFromJSON(json['message']),
        'code': json['code'],
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
        'durationMillis': !exists(json, 'durationMillis') ? undefined : json['durationMillis'],
        'state': json['state'],
    };
}

export function PipelineStatusV1StepsInnerDtoToJSON(value?: PipelineStatusV1StepsInnerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': PipelineStatusV1StepsInnerMessageDtoToJSON(value.message),
        'code': value.code,
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'durationMillis': value.durationMillis,
        'state': value.state,
    };
}

