/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { APIVersionV1Dto } from './APIVersionV1Dto';
import {
    APIVersionV1DtoFromJSON,
    APIVersionV1DtoFromJSONTyped,
    APIVersionV1DtoToJSON,
} from './APIVersionV1Dto';
import type { ObjectMetaV1Dto } from './ObjectMetaV1Dto';
import {
    ObjectMetaV1DtoFromJSON,
    ObjectMetaV1DtoFromJSONTyped,
    ObjectMetaV1DtoToJSON,
} from './ObjectMetaV1Dto';
import type { ServiceSpecV1Dto } from './ServiceSpecV1Dto';
import {
    ServiceSpecV1DtoFromJSON,
    ServiceSpecV1DtoFromJSONTyped,
    ServiceSpecV1DtoToJSON,
} from './ServiceSpecV1Dto';
import type { ServiceStatusV1Dto } from './ServiceStatusV1Dto';
import {
    ServiceStatusV1DtoFromJSON,
    ServiceStatusV1DtoFromJSONTyped,
    ServiceStatusV1DtoToJSON,
} from './ServiceStatusV1Dto';

/**
 * 
 * @export
 * @interface ServiceV1Dto
 */
export interface ServiceV1Dto {
    /**
     * 
     * @type {APIVersionV1Dto}
     * @memberof ServiceV1Dto
     */
    apiVersion: APIVersionV1Dto;
    /**
     * 
     * @type {string}
     * @memberof ServiceV1Dto
     */
    kind: ServiceV1DtoKindEnum;
    /**
     * 
     * @type {ObjectMetaV1Dto}
     * @memberof ServiceV1Dto
     */
    metadata: ObjectMetaV1Dto;
    /**
     * 
     * @type {ServiceSpecV1Dto}
     * @memberof ServiceV1Dto
     */
    spec: ServiceSpecV1Dto;
    /**
     * 
     * @type {ServiceStatusV1Dto}
     * @memberof ServiceV1Dto
     */
    status?: ServiceStatusV1Dto;
}


/**
 * @export
 */
export const ServiceV1DtoKindEnum = {
    Service: 'Service'
} as const;
export type ServiceV1DtoKindEnum = typeof ServiceV1DtoKindEnum[keyof typeof ServiceV1DtoKindEnum];


/**
 * Check if a given object implements the ServiceV1Dto interface.
 */
export function instanceOfServiceV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apiVersion" in value;
    isInstance = isInstance && "kind" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "spec" in value;

    return isInstance;
}

export function ServiceV1DtoFromJSON(json: any): ServiceV1Dto {
    return ServiceV1DtoFromJSONTyped(json, false);
}

export function ServiceV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiVersion': APIVersionV1DtoFromJSON(json['apiVersion']),
        'kind': json['kind'],
        'metadata': ObjectMetaV1DtoFromJSON(json['metadata']),
        'spec': ServiceSpecV1DtoFromJSON(json['spec']),
        'status': !exists(json, 'status') ? undefined : ServiceStatusV1DtoFromJSON(json['status']),
    };
}

export function ServiceV1DtoToJSON(value?: ServiceV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiVersion': APIVersionV1DtoToJSON(value.apiVersion),
        'kind': value.kind,
        'metadata': ObjectMetaV1DtoToJSON(value.metadata),
        'spec': ServiceSpecV1DtoToJSON(value.spec),
        'status': ServiceStatusV1DtoToJSON(value.status),
    };
}

