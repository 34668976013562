/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto';
import type { RevisionTemplateSpecV1Dto } from './RevisionTemplateSpecV1Dto';
import {
    RevisionTemplateSpecV1DtoFromJSON,
    RevisionTemplateSpecV1DtoFromJSONTyped,
    RevisionTemplateSpecV1DtoToJSON,
} from './RevisionTemplateSpecV1Dto';
import type { ServiceSpecV1RolloutDto } from './ServiceSpecV1RolloutDto';
import {
    ServiceSpecV1RolloutDtoFromJSON,
    ServiceSpecV1RolloutDtoFromJSONTyped,
    ServiceSpecV1RolloutDtoToJSON,
} from './ServiceSpecV1RolloutDto';

/**
 * 
 * @export
 * @interface ServiceSpecV1Dto
 */
export interface ServiceSpecV1Dto {
    /**
     * 
     * @type {string}
     * @memberof ServiceSpecV1Dto
     */
    githubRepositoryName?: string;
    /**
     * 
     * @type {RevisionTemplateSpecV1Dto}
     * @memberof ServiceSpecV1Dto
     */
    template: RevisionTemplateSpecV1Dto;
    /**
     * 
     * @type {ServiceSpecV1RolloutDto}
     * @memberof ServiceSpecV1Dto
     */
    rollout: ServiceSpecV1RolloutDto;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto}
     * @memberof ServiceSpecV1Dto
     */
    imageUpdatePolicy: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceSpecV1Dto
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceSpecV1Dto
     */
    scaleDownAfterDays?: number;
}

/**
 * Check if a given object implements the ServiceSpecV1Dto interface.
 */
export function instanceOfServiceSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "template" in value;
    isInstance = isInstance && "rollout" in value;
    isInstance = isInstance && "imageUpdatePolicy" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function ServiceSpecV1DtoFromJSON(json: any): ServiceSpecV1Dto {
    return ServiceSpecV1DtoFromJSONTyped(json, false);
}

export function ServiceSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'githubRepositoryName': !exists(json, 'githubRepositoryName') ? undefined : json['githubRepositoryName'],
        'template': RevisionTemplateSpecV1DtoFromJSON(json['template']),
        'rollout': ServiceSpecV1RolloutDtoFromJSON(json['rollout']),
        'imageUpdatePolicy': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoFromJSON(json['imageUpdatePolicy']),
        'active': json['active'],
        'scaleDownAfterDays': !exists(json, 'scaleDownAfterDays') ? undefined : json['scaleDownAfterDays'],
    };
}

export function ServiceSpecV1DtoToJSON(value?: ServiceSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'githubRepositoryName': value.githubRepositoryName,
        'template': RevisionTemplateSpecV1DtoToJSON(value.template),
        'rollout': ServiceSpecV1RolloutDtoToJSON(value.rollout),
        'imageUpdatePolicy': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoToJSON(value.imageUpdatePolicy),
        'active': value.active,
        'scaleDownAfterDays': value.scaleDownAfterDays,
    };
}

