/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RevisionTemplateSpecV1ResourcesDto
 */
export interface RevisionTemplateSpecV1ResourcesDto {
    /**
     * 1 CPU core = 1000 millicores
     * @type {number}
     * @memberof RevisionTemplateSpecV1ResourcesDto
     */
    cpuMillicores: number;
    /**
     * 
     * @type {number}
     * @memberof RevisionTemplateSpecV1ResourcesDto
     */
    memoryMegabytes: number;
}

/**
 * Check if a given object implements the RevisionTemplateSpecV1ResourcesDto interface.
 */
export function instanceOfRevisionTemplateSpecV1ResourcesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cpuMillicores" in value;
    isInstance = isInstance && "memoryMegabytes" in value;

    return isInstance;
}

export function RevisionTemplateSpecV1ResourcesDtoFromJSON(json: any): RevisionTemplateSpecV1ResourcesDto {
    return RevisionTemplateSpecV1ResourcesDtoFromJSONTyped(json, false);
}

export function RevisionTemplateSpecV1ResourcesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionTemplateSpecV1ResourcesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cpuMillicores': json['cpuMillicores'],
        'memoryMegabytes': json['memoryMegabytes'],
    };
}

export function RevisionTemplateSpecV1ResourcesDtoToJSON(value?: RevisionTemplateSpecV1ResourcesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cpuMillicores': value.cpuMillicores,
        'memoryMegabytes': value.memoryMegabytes,
    };
}

