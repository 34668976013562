/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CPUBasedScalingSpecV1Dto } from './CPUBasedScalingSpecV1Dto';
import {
    instanceOfCPUBasedScalingSpecV1Dto,
    CPUBasedScalingSpecV1DtoFromJSON,
    CPUBasedScalingSpecV1DtoFromJSONTyped,
    CPUBasedScalingSpecV1DtoToJSON,
} from './CPUBasedScalingSpecV1Dto';
import type { FixedScalingSpecV1Dto } from './FixedScalingSpecV1Dto';
import {
    instanceOfFixedScalingSpecV1Dto,
    FixedScalingSpecV1DtoFromJSON,
    FixedScalingSpecV1DtoFromJSONTyped,
    FixedScalingSpecV1DtoToJSON,
} from './FixedScalingSpecV1Dto';

/**
 * @type ScalingSpecV1Dto
 * 
 * @export
 */
export type ScalingSpecV1Dto = { scalingType: 'CPU_BASED' } & CPUBasedScalingSpecV1Dto | { scalingType: 'FIXED' } & FixedScalingSpecV1Dto;

export function ScalingSpecV1DtoFromJSON(json: any): ScalingSpecV1Dto {
    return ScalingSpecV1DtoFromJSONTyped(json, false);
}

export function ScalingSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScalingSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['scalingType']) {
        case 'CPU_BASED':
            return {...CPUBasedScalingSpecV1DtoFromJSONTyped(json, true), scalingType: 'CPU_BASED'};
        case 'FIXED':
            return {...FixedScalingSpecV1DtoFromJSONTyped(json, true), scalingType: 'FIXED'};
        default:
            throw new Error(`No variant of ScalingSpecV1Dto exists with 'scalingType=${json['scalingType']}'`);
    }
}

export function ScalingSpecV1DtoToJSON(value?: ScalingSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['scalingType']) {
        case 'CPU_BASED':
            return CPUBasedScalingSpecV1DtoToJSON(value);
        case 'FIXED':
            return FixedScalingSpecV1DtoToJSON(value);
        default:
            throw new Error(`No variant of ScalingSpecV1Dto exists with 'scalingType=${value['scalingType']}'`);
    }

}

