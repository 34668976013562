/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const HarborProjectStateV1Dto = {
    Pending: 'PENDING',
    Running: 'RUNNING',
    Completed: 'COMPLETED',
    Failed: 'FAILED'
} as const;
export type HarborProjectStateV1Dto = typeof HarborProjectStateV1Dto[keyof typeof HarborProjectStateV1Dto];


export function HarborProjectStateV1DtoFromJSON(json: any): HarborProjectStateV1Dto {
    return HarborProjectStateV1DtoFromJSONTyped(json, false);
}

export function HarborProjectStateV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborProjectStateV1Dto {
    return json as HarborProjectStateV1Dto;
}

export function HarborProjectStateV1DtoToJSON(value?: HarborProjectStateV1Dto | null): any {
    return value as any;
}

