/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const APIVersionV1Dto = {
    V1: 'v1'
} as const;
export type APIVersionV1Dto = typeof APIVersionV1Dto[keyof typeof APIVersionV1Dto];


export function APIVersionV1DtoFromJSON(json: any): APIVersionV1Dto {
    return APIVersionV1DtoFromJSONTyped(json, false);
}

export function APIVersionV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIVersionV1Dto {
    return json as APIVersionV1Dto;
}

export function APIVersionV1DtoToJSON(value?: APIVersionV1Dto | null): any {
    return value as any;
}

