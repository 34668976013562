/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActivityLogV1Dto,
  ActivityLogsPageDto,
  ApplicationQuickstartPipelineV1Dto,
  ApplicationV1Dto,
  ApplicationsPageDto,
  EnvVarSpecV1Dto,
  GithubRepositoryTemplateV1Dto,
  HarborEventV1Dto,
  HarborProjectV1Dto,
  InfraBrokerV1Dto,
  ProblemDetailDto,
  RepositoryV1Dto,
  RevisionV1Dto,
  SecretFilePreviewSpecV1Dto,
  SecretGroupV1Dto,
  ServicePipelineV1Dto,
  ServicePipelinesPageDto,
  ServiceQuickstartPipelineV1Dto,
  ServiceTagV1Dto,
  ServiceV1Dto,
} from '../models/index';
import {
    ActivityLogV1DtoFromJSON,
    ActivityLogV1DtoToJSON,
    ActivityLogsPageDtoFromJSON,
    ActivityLogsPageDtoToJSON,
    ApplicationQuickstartPipelineV1DtoFromJSON,
    ApplicationQuickstartPipelineV1DtoToJSON,
    ApplicationV1DtoFromJSON,
    ApplicationV1DtoToJSON,
    ApplicationsPageDtoFromJSON,
    ApplicationsPageDtoToJSON,
    EnvVarSpecV1DtoFromJSON,
    EnvVarSpecV1DtoToJSON,
    GithubRepositoryTemplateV1DtoFromJSON,
    GithubRepositoryTemplateV1DtoToJSON,
    HarborEventV1DtoFromJSON,
    HarborEventV1DtoToJSON,
    HarborProjectV1DtoFromJSON,
    HarborProjectV1DtoToJSON,
    InfraBrokerV1DtoFromJSON,
    InfraBrokerV1DtoToJSON,
    ProblemDetailDtoFromJSON,
    ProblemDetailDtoToJSON,
    RepositoryV1DtoFromJSON,
    RepositoryV1DtoToJSON,
    RevisionV1DtoFromJSON,
    RevisionV1DtoToJSON,
    SecretFilePreviewSpecV1DtoFromJSON,
    SecretFilePreviewSpecV1DtoToJSON,
    SecretGroupV1DtoFromJSON,
    SecretGroupV1DtoToJSON,
    ServicePipelineV1DtoFromJSON,
    ServicePipelineV1DtoToJSON,
    ServicePipelinesPageDtoFromJSON,
    ServicePipelinesPageDtoToJSON,
    ServiceQuickstartPipelineV1DtoFromJSON,
    ServiceQuickstartPipelineV1DtoToJSON,
    ServiceTagV1DtoFromJSON,
    ServiceTagV1DtoToJSON,
    ServiceV1DtoFromJSON,
    ServiceV1DtoToJSON,
} from '../models/index';

export interface CreateActivityLogRequest {
    activityLogV1Dto: ActivityLogV1Dto;
}

export interface CreateApplicationRequest {
    applicationV1Dto: ApplicationV1Dto;
    macAppId?: string;
    macPlatformId?: string;
}

export interface CreateApplicationQuickstartPipelineRequest {
    applicationQuickstartPipelineV1Dto: ApplicationQuickstartPipelineV1Dto;
}

export interface CreateApplicationV1Request {
    applicationV1Dto: ApplicationV1Dto;
}

export interface CreateApplicationWithTeamRequest {
    applicationV1Dto: ApplicationV1Dto;
    createTeam: boolean;
    macAppId?: string;
    macPlatformId?: string;
}

export interface CreateEnvVarsPreviewRequest {
    requestBody: { [key: string]: EnvVarSpecV1Dto | undefined; };
    application: string;
    environment: string;
    service: string;
}

export interface CreateHarborEventRequest {
    harborEventV1Dto: HarborEventV1Dto;
}

export interface CreateHarborProjectRequest {
    harborProjectV1Dto: HarborProjectV1Dto;
    application: string;
}

export interface CreateInfraBrokerRequest {
    infraBrokerV1Dto: InfraBrokerV1Dto;
}

export interface CreateRevisionRequest {
    revisionV1Dto: RevisionV1Dto;
    application: string;
    environment: string;
}

export interface CreateSecretFilePreviewRequest {
    secretFilePreviewSpecV1Dto: SecretFilePreviewSpecV1Dto;
    application: string;
    environment: string;
    service: string;
}

export interface CreateSecretGroupRequest {
    secretGroupV1Dto: SecretGroupV1Dto;
    application: string;
    environment: string;
}

export interface CreateServiceRequest {
    serviceV1Dto: ServiceV1Dto;
    application: string;
    environment: string;
    xServiceCopy?: boolean;
}

export interface CreateServicePipelineRequest {
    servicePipelineV1Dto: ServicePipelineV1Dto;
    application: string;
    environment: string;
    service: string;
}

export interface CreateServiceQuickstartPipelineRequest {
    serviceQuickstartPipelineV1Dto: ServiceQuickstartPipelineV1Dto;
}

export interface DeleteApplicationRequest {
    application: string;
    forceDelete?: boolean;
}

export interface DeleteHarborProjectRequest {
    application: string;
}

export interface DeleteInfraBrokerRequest {
    infrabroker: string;
}

export interface DeleteRevisionRequest {
    application: string;
    environment: string;
    revision: string;
}

export interface DeleteRevisionInstanceRequest {
    application: string;
    environment: string;
    revision: string;
    instance: string;
}

export interface DeleteSecretGroupRequest {
    application: string;
    environment: string;
    secretGroup: string;
    version?: number;
}

export interface DeleteServiceRequest {
    application: string;
    environment: string;
    service: string;
}

export interface GetActivityLogRequest {
    activitylog: string;
}

export interface GetActivityLogsRequest {
    applicationName: string;
    page?: number;
    size?: number;
    environmentName?: string;
    serviceName?: string;
    revisionName?: string;
    instanceName?: string;
}

export interface GetApplicationRequest {
    application: string;
}

export interface GetApplicationQuickstartPipelineRequest {
    pipelineName: string;
}

export interface GetApplicationsRequest {
    teamRefs?: Array<string>;
}

export interface GetHarborProjectForApplicationRequest {
    application: string;
}

export interface GetPagedApplicationsRequest {
    page?: number;
    size?: number;
    teamRefs?: Array<string>;
    applicationNameSearchKey?: string;
}

export interface GetRepositoriesRequest {
    query: string;
}

export interface GetRevisionRequest {
    application: string;
    environment: string;
    revision: string;
}

export interface GetRevisionsRequest {
    application: string;
    environment: string;
    service: string;
    onlyActive?: boolean;
}

export interface GetSecretGroupRequest {
    application: string;
    environment: string;
    secretGroup: string;
    version?: number;
}

export interface GetSecretGroupsRequest {
    application: string;
    environment: string;
}

export interface GetServiceRequest {
    application: string;
    environment: string;
    service: string;
}

export interface GetServicePipelineRequest {
    application: string;
    environment: string;
    service: string;
    pipelineName: string;
}

export interface GetServicePipelinesRequest {
    application: string;
    environment: string;
    service: string;
    page?: number;
    size?: number;
}

export interface GetServiceQuickstartPipelineRequest {
    pipelineName: string;
}

export interface GetServiceTagsRequest {
    application: string;
    environment: string;
    service: string;
}

export interface GetServicesRequest {
    application: string;
    environment: string;
}

export interface PatchServiceRequest {
    requestBody: { [key: string]: object | undefined; };
    application: string;
    environment: string;
    service: string;
}

export interface UpdateHarborProjectRequest {
    harborProjectV1Dto: HarborProjectV1Dto;
    application: string;
}

export interface UpdateInfraBrokerRequest {
    infraBrokerV1Dto: InfraBrokerV1Dto;
    infrabroker: string;
}

export interface UpdateRevisionRequest {
    requestBody: { [key: string]: object | undefined; };
    application: string;
    environment: string;
    revision: string;
}

export interface UpdateRevisionEnabledRequest {
    application: string;
    environment: string;
    revision: string;
    enabled: boolean;
}

export interface UpdateSecretGroupRequest {
    secretGroupV1Dto: SecretGroupV1Dto;
    application: string;
    environment: string;
    secretGroup: string;
}

export interface UpdateServiceRequest {
    serviceV1Dto: ServiceV1Dto;
    application: string;
    environment: string;
    service: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create activity log
     */
    async createActivityLogRaw(requestParameters: CreateActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActivityLogV1Dto>> {
        if (requestParameters.activityLogV1Dto === null || requestParameters.activityLogV1Dto === undefined) {
            throw new runtime.RequiredError('activityLogV1Dto','Required parameter requestParameters.activityLogV1Dto was null or undefined when calling createActivityLog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activitylogs/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityLogV1DtoToJSON(requestParameters.activityLogV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityLogV1DtoFromJSON(jsonValue));
    }

    /**
     * Create activity log
     */
    async createActivityLog(activityLogV1Dto: ActivityLogV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActivityLogV1Dto> {
        const response = await this.createActivityLogRaw({ activityLogV1Dto: activityLogV1Dto }, initOverrides);
        return await response.value();
    }

    /**
     * Create application
     */
    async createApplicationRaw(requestParameters: CreateApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.applicationV1Dto === null || requestParameters.applicationV1Dto === undefined) {
            throw new runtime.RequiredError('applicationV1Dto','Required parameter requestParameters.applicationV1Dto was null or undefined when calling createApplication.');
        }

        const queryParameters: any = {};

        if (requestParameters.macAppId !== undefined) {
            queryParameters['macAppId'] = requestParameters.macAppId;
        }

        if (requestParameters.macPlatformId !== undefined) {
            queryParameters['macPlatformId'] = requestParameters.macPlatformId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationV1DtoToJSON(requestParameters.applicationV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create application
     */
    async createApplication(applicationV1Dto: ApplicationV1Dto, macAppId?: string, macPlatformId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createApplicationRaw({ applicationV1Dto: applicationV1Dto, macAppId: macAppId, macPlatformId: macPlatformId }, initOverrides);
    }

    /**
     * Create application quickstart pipeline
     */
    async createApplicationQuickstartPipelineRaw(requestParameters: CreateApplicationQuickstartPipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationQuickstartPipelineV1Dto>> {
        if (requestParameters.applicationQuickstartPipelineV1Dto === null || requestParameters.applicationQuickstartPipelineV1Dto === undefined) {
            throw new runtime.RequiredError('applicationQuickstartPipelineV1Dto','Required parameter requestParameters.applicationQuickstartPipelineV1Dto was null or undefined when calling createApplicationQuickstartPipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationquickstartpipelines/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationQuickstartPipelineV1DtoToJSON(requestParameters.applicationQuickstartPipelineV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationQuickstartPipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Create application quickstart pipeline
     */
    async createApplicationQuickstartPipeline(applicationQuickstartPipelineV1Dto: ApplicationQuickstartPipelineV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationQuickstartPipelineV1Dto> {
        const response = await this.createApplicationQuickstartPipelineRaw({ applicationQuickstartPipelineV1Dto: applicationQuickstartPipelineV1Dto }, initOverrides);
        return await response.value();
    }

    /**
     * Create application
     * @deprecated
     */
    async createApplicationV1Raw(requestParameters: CreateApplicationV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.applicationV1Dto === null || requestParameters.applicationV1Dto === undefined) {
            throw new runtime.RequiredError('applicationV1Dto','Required parameter requestParameters.applicationV1Dto was null or undefined when calling createApplicationV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationV1DtoToJSON(requestParameters.applicationV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create application
     * @deprecated
     */
    async createApplicationV1(applicationV1Dto: ApplicationV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createApplicationV1Raw({ applicationV1Dto: applicationV1Dto }, initOverrides);
    }

    /**
     * Create application (with a create team flag). Endpoint dedicated for MDP front-end
     */
    async createApplicationWithTeamRaw(requestParameters: CreateApplicationWithTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.applicationV1Dto === null || requestParameters.applicationV1Dto === undefined) {
            throw new runtime.RequiredError('applicationV1Dto','Required parameter requestParameters.applicationV1Dto was null or undefined when calling createApplicationWithTeam.');
        }

        if (requestParameters.createTeam === null || requestParameters.createTeam === undefined) {
            throw new runtime.RequiredError('createTeam','Required parameter requestParameters.createTeam was null or undefined when calling createApplicationWithTeam.');
        }

        const queryParameters: any = {};

        if (requestParameters.createTeam !== undefined) {
            queryParameters['createTeam'] = requestParameters.createTeam;
        }

        if (requestParameters.macAppId !== undefined) {
            queryParameters['macAppId'] = requestParameters.macAppId;
        }

        if (requestParameters.macPlatformId !== undefined) {
            queryParameters['macPlatformId'] = requestParameters.macPlatformId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationV1DtoToJSON(requestParameters.applicationV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create application (with a create team flag). Endpoint dedicated for MDP front-end
     */
    async createApplicationWithTeam(applicationV1Dto: ApplicationV1Dto, createTeam: boolean, macAppId?: string, macPlatformId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createApplicationWithTeamRaw({ applicationV1Dto: applicationV1Dto, createTeam: createTeam, macAppId: macAppId, macPlatformId: macPlatformId }, initOverrides);
    }

    /**
     * Returns environment preview based on service data.
     */
    async createEnvVarsPreviewRaw(requestParameters: CreateEnvVarsPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: string | undefined; }>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling createEnvVarsPreview.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createEnvVarsPreview.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createEnvVarsPreview.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling createEnvVarsPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/envvarspreviews/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns environment preview based on service data.
     */
    async createEnvVarsPreview(requestBody: { [key: string]: EnvVarSpecV1Dto | undefined; }, application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: string | undefined; }> {
        const response = await this.createEnvVarsPreviewRaw({ requestBody: requestBody, application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Create harbor event
     */
    async createHarborEventRaw(requestParameters: CreateHarborEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.harborEventV1Dto === null || requestParameters.harborEventV1Dto === undefined) {
            throw new runtime.RequiredError('harborEventV1Dto','Required parameter requestParameters.harborEventV1Dto was null or undefined when calling createHarborEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/harborevent/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HarborEventV1DtoToJSON(requestParameters.harborEventV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create harbor event
     */
    async createHarborEvent(harborEventV1Dto: HarborEventV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createHarborEventRaw({ harborEventV1Dto: harborEventV1Dto }, initOverrides);
    }

    /**
     * Create harbor project
     */
    async createHarborProjectRaw(requestParameters: CreateHarborProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.harborProjectV1Dto === null || requestParameters.harborProjectV1Dto === undefined) {
            throw new runtime.RequiredError('harborProjectV1Dto','Required parameter requestParameters.harborProjectV1Dto was null or undefined when calling createHarborProject.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createHarborProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/harborprojects/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HarborProjectV1DtoToJSON(requestParameters.harborProjectV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create harbor project
     */
    async createHarborProject(harborProjectV1Dto: HarborProjectV1Dto, application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createHarborProjectRaw({ harborProjectV1Dto: harborProjectV1Dto, application: application }, initOverrides);
    }

    /**
     * Create infra broker registration
     */
    async createInfraBrokerRaw(requestParameters: CreateInfraBrokerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfraBrokerV1Dto>> {
        if (requestParameters.infraBrokerV1Dto === null || requestParameters.infraBrokerV1Dto === undefined) {
            throw new runtime.RequiredError('infraBrokerV1Dto','Required parameter requestParameters.infraBrokerV1Dto was null or undefined when calling createInfraBroker.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/infrabrokers/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfraBrokerV1DtoToJSON(requestParameters.infraBrokerV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfraBrokerV1DtoFromJSON(jsonValue));
    }

    /**
     * Create infra broker registration
     */
    async createInfraBroker(infraBrokerV1Dto: InfraBrokerV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfraBrokerV1Dto> {
        const response = await this.createInfraBrokerRaw({ infraBrokerV1Dto: infraBrokerV1Dto }, initOverrides);
        return await response.value();
    }

    /**
     * Create a new server-group within an application environment.
     */
    async createRevisionRaw(requestParameters: CreateRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.revisionV1Dto === null || requestParameters.revisionV1Dto === undefined) {
            throw new runtime.RequiredError('revisionV1Dto','Required parameter requestParameters.revisionV1Dto was null or undefined when calling createRevision.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createRevision.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RevisionV1DtoToJSON(requestParameters.revisionV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new server-group within an application environment.
     */
    async createRevision(revisionV1Dto: RevisionV1Dto, application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRevisionRaw({ revisionV1Dto: revisionV1Dto, application: application, environment: environment }, initOverrides);
    }

    /**
     * Returns secret file rendered contents as it will be provided to workload.
     */
    async createSecretFilePreviewRaw(requestParameters: CreateSecretFilePreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.secretFilePreviewSpecV1Dto === null || requestParameters.secretFilePreviewSpecV1Dto === undefined) {
            throw new runtime.RequiredError('secretFilePreviewSpecV1Dto','Required parameter requestParameters.secretFilePreviewSpecV1Dto was null or undefined when calling createSecretFilePreview.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createSecretFilePreview.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createSecretFilePreview.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling createSecretFilePreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/secretfilepreview`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecretFilePreviewSpecV1DtoToJSON(requestParameters.secretFilePreviewSpecV1Dto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns secret file rendered contents as it will be provided to workload.
     */
    async createSecretFilePreview(secretFilePreviewSpecV1Dto: SecretFilePreviewSpecV1Dto, application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createSecretFilePreviewRaw({ secretFilePreviewSpecV1Dto: secretFilePreviewSpecV1Dto, application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Create secret group
     */
    async createSecretGroupRaw(requestParameters: CreateSecretGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecretGroupV1Dto>> {
        if (requestParameters.secretGroupV1Dto === null || requestParameters.secretGroupV1Dto === undefined) {
            throw new runtime.RequiredError('secretGroupV1Dto','Required parameter requestParameters.secretGroupV1Dto was null or undefined when calling createSecretGroup.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createSecretGroup.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createSecretGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/secretgroups/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecretGroupV1DtoToJSON(requestParameters.secretGroupV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecretGroupV1DtoFromJSON(jsonValue));
    }

    /**
     * Create secret group
     */
    async createSecretGroup(secretGroupV1Dto: SecretGroupV1Dto, application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecretGroupV1Dto> {
        const response = await this.createSecretGroupRaw({ secretGroupV1Dto: secretGroupV1Dto, application: application, environment: environment }, initOverrides);
        return await response.value();
    }

    /**
     * Create service
     */
    async createServiceRaw(requestParameters: CreateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceV1Dto>> {
        if (requestParameters.serviceV1Dto === null || requestParameters.serviceV1Dto === undefined) {
            throw new runtime.RequiredError('serviceV1Dto','Required parameter requestParameters.serviceV1Dto was null or undefined when calling createService.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createService.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xServiceCopy !== undefined && requestParameters.xServiceCopy !== null) {
            headerParameters['X-Service-Copy'] = String(requestParameters.xServiceCopy);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceV1DtoToJSON(requestParameters.serviceV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceV1DtoFromJSON(jsonValue));
    }

    /**
     * Create service
     */
    async createService(serviceV1Dto: ServiceV1Dto, application: string, environment: string, xServiceCopy?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceV1Dto> {
        const response = await this.createServiceRaw({ serviceV1Dto: serviceV1Dto, application: application, environment: environment, xServiceCopy: xServiceCopy }, initOverrides);
        return await response.value();
    }

    /**
     * Create service pipeline
     */
    async createServicePipelineRaw(requestParameters: CreateServicePipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServicePipelineV1Dto>> {
        if (requestParameters.servicePipelineV1Dto === null || requestParameters.servicePipelineV1Dto === undefined) {
            throw new runtime.RequiredError('servicePipelineV1Dto','Required parameter requestParameters.servicePipelineV1Dto was null or undefined when calling createServicePipeline.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createServicePipeline.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createServicePipeline.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling createServicePipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/pipelines/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServicePipelineV1DtoToJSON(requestParameters.servicePipelineV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServicePipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Create service pipeline
     */
    async createServicePipeline(servicePipelineV1Dto: ServicePipelineV1Dto, application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServicePipelineV1Dto> {
        const response = await this.createServicePipelineRaw({ servicePipelineV1Dto: servicePipelineV1Dto, application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Create service quickstart pipeline
     */
    async createServiceQuickstartPipelineRaw(requestParameters: CreateServiceQuickstartPipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceQuickstartPipelineV1Dto>> {
        if (requestParameters.serviceQuickstartPipelineV1Dto === null || requestParameters.serviceQuickstartPipelineV1Dto === undefined) {
            throw new runtime.RequiredError('serviceQuickstartPipelineV1Dto','Required parameter requestParameters.serviceQuickstartPipelineV1Dto was null or undefined when calling createServiceQuickstartPipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/servicequickstartpipelines/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceQuickstartPipelineV1DtoToJSON(requestParameters.serviceQuickstartPipelineV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceQuickstartPipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Create service quickstart pipeline
     */
    async createServiceQuickstartPipeline(serviceQuickstartPipelineV1Dto: ServiceQuickstartPipelineV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceQuickstartPipelineV1Dto> {
        const response = await this.createServiceQuickstartPipelineRaw({ serviceQuickstartPipelineV1Dto: serviceQuickstartPipelineV1Dto }, initOverrides);
        return await response.value();
    }

    /**
     * Delete application
     */
    async deleteApplicationRaw(requestParameters: DeleteApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteApplication.');
        }

        const queryParameters: any = {};

        if (requestParameters.forceDelete !== undefined) {
            queryParameters['forceDelete'] = requestParameters.forceDelete;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete application
     */
    async deleteApplication(application: string, forceDelete?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteApplicationRaw({ application: application, forceDelete: forceDelete }, initOverrides);
    }

    /**
     * Delete the harbor project. This operation is asynchronous. Harbor Project considered to be deleted  when corresponding \'GET\' endpoint returns 404 status code. 
     */
    async deleteHarborProjectRaw(requestParameters: DeleteHarborProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteHarborProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/harborprojects/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the harbor project. This operation is asynchronous. Harbor Project considered to be deleted  when corresponding \'GET\' endpoint returns 404 status code. 
     */
    async deleteHarborProject(application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteHarborProjectRaw({ application: application }, initOverrides);
    }

    /**
     * Delete infra broker
     */
    async deleteInfraBrokerRaw(requestParameters: DeleteInfraBrokerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.infrabroker === null || requestParameters.infrabroker === undefined) {
            throw new runtime.RequiredError('infrabroker','Required parameter requestParameters.infrabroker was null or undefined when calling deleteInfraBroker.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/infrabrokers/v1/{infrabroker}`.replace(`{${"infrabroker"}}`, encodeURIComponent(String(requestParameters.infrabroker))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete infra broker
     */
    async deleteInfraBroker(infrabroker: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteInfraBrokerRaw({ infrabroker: infrabroker }, initOverrides);
    }

    /**
     * Delete revision
     */
    async deleteRevisionRaw(requestParameters: DeleteRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteRevision.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteRevision.');
        }

        if (requestParameters.revision === null || requestParameters.revision === undefined) {
            throw new runtime.RequiredError('revision','Required parameter requestParameters.revision was null or undefined when calling deleteRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/v1/{revision}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"revision"}}`, encodeURIComponent(String(requestParameters.revision))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete revision
     */
    async deleteRevision(application: string, environment: string, revision: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRevisionRaw({ application: application, environment: environment, revision: revision }, initOverrides);
    }

    /**
     * Delete an instance
     */
    async deleteRevisionInstanceRaw(requestParameters: DeleteRevisionInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteRevisionInstance.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteRevisionInstance.');
        }

        if (requestParameters.revision === null || requestParameters.revision === undefined) {
            throw new runtime.RequiredError('revision','Required parameter requestParameters.revision was null or undefined when calling deleteRevisionInstance.');
        }

        if (requestParameters.instance === null || requestParameters.instance === undefined) {
            throw new runtime.RequiredError('instance','Required parameter requestParameters.instance was null or undefined when calling deleteRevisionInstance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/{revision}/instances/v1/{instance}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"revision"}}`, encodeURIComponent(String(requestParameters.revision))).replace(`{${"instance"}}`, encodeURIComponent(String(requestParameters.instance))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an instance
     */
    async deleteRevisionInstance(application: string, environment: string, revision: string, instance: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRevisionInstanceRaw({ application: application, environment: environment, revision: revision, instance: instance }, initOverrides);
    }

    /**
     * Delete secret group
     */
    async deleteSecretGroupRaw(requestParameters: DeleteSecretGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteSecretGroup.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteSecretGroup.');
        }

        if (requestParameters.secretGroup === null || requestParameters.secretGroup === undefined) {
            throw new runtime.RequiredError('secretGroup','Required parameter requestParameters.secretGroup was null or undefined when calling deleteSecretGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/secretgroups/v1/{secretGroup}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"secretGroup"}}`, encodeURIComponent(String(requestParameters.secretGroup))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete secret group
     */
    async deleteSecretGroup(application: string, environment: string, secretGroup: string, version?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSecretGroupRaw({ application: application, environment: environment, secretGroup: secretGroup, version: version }, initOverrides);
    }

    /**
     * Delete service
     */
    async deleteServiceRaw(requestParameters: DeleteServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteService.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteService.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling deleteService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1/{service}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete service
     */
    async deleteService(application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteServiceRaw({ application: application, environment: environment, service: service }, initOverrides);
    }

    /**
     * Get single activity log
     */
    async getActivityLogRaw(requestParameters: GetActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActivityLogV1Dto>> {
        if (requestParameters.activitylog === null || requestParameters.activitylog === undefined) {
            throw new runtime.RequiredError('activitylog','Required parameter requestParameters.activitylog was null or undefined when calling getActivityLog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activitylogs/v1/{activitylog}`.replace(`{${"activitylog"}}`, encodeURIComponent(String(requestParameters.activitylog))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityLogV1DtoFromJSON(jsonValue));
    }

    /**
     * Get single activity log
     */
    async getActivityLog(activitylog: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActivityLogV1Dto> {
        const response = await this.getActivityLogRaw({ activitylog: activitylog }, initOverrides);
        return await response.value();
    }

    /**
     * Get activity log s related to particular environment
     */
    async getActivityLogsRaw(requestParameters: GetActivityLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActivityLogsPageDto>> {
        if (requestParameters.applicationName === null || requestParameters.applicationName === undefined) {
            throw new runtime.RequiredError('applicationName','Required parameter requestParameters.applicationName was null or undefined when calling getActivityLogs.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.applicationName !== undefined) {
            queryParameters['applicationName'] = requestParameters.applicationName;
        }

        if (requestParameters.environmentName !== undefined) {
            queryParameters['environmentName'] = requestParameters.environmentName;
        }

        if (requestParameters.serviceName !== undefined) {
            queryParameters['serviceName'] = requestParameters.serviceName;
        }

        if (requestParameters.revisionName !== undefined) {
            queryParameters['revisionName'] = requestParameters.revisionName;
        }

        if (requestParameters.instanceName !== undefined) {
            queryParameters['instanceName'] = requestParameters.instanceName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activitylogs/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityLogsPageDtoFromJSON(jsonValue));
    }

    /**
     * Get activity log s related to particular environment
     */
    async getActivityLogs(applicationName: string, page?: number, size?: number, environmentName?: string, serviceName?: string, revisionName?: string, instanceName?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActivityLogsPageDto> {
        const response = await this.getActivityLogsRaw({ applicationName: applicationName, page: page, size: size, environmentName: environmentName, serviceName: serviceName, revisionName: revisionName, instanceName: instanceName }, initOverrides);
        return await response.value();
    }

    /**
     * Get application
     */
    async getApplicationRaw(requestParameters: GetApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationV1DtoFromJSON(jsonValue));
    }

    /**
     * Get application
     */
    async getApplication(application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationV1Dto> {
        const response = await this.getApplicationRaw({ application: application }, initOverrides);
        return await response.value();
    }

    /**
     * Get an application quickstart pipeline
     */
    async getApplicationQuickstartPipelineRaw(requestParameters: GetApplicationQuickstartPipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationQuickstartPipelineV1Dto>> {
        if (requestParameters.pipelineName === null || requestParameters.pipelineName === undefined) {
            throw new runtime.RequiredError('pipelineName','Required parameter requestParameters.pipelineName was null or undefined when calling getApplicationQuickstartPipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationquickstartpipelines/v1/{pipelineName}`.replace(`{${"pipelineName"}}`, encodeURIComponent(String(requestParameters.pipelineName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationQuickstartPipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Get an application quickstart pipeline
     */
    async getApplicationQuickstartPipeline(pipelineName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationQuickstartPipelineV1Dto> {
        const response = await this.getApplicationQuickstartPipelineRaw({ pipelineName: pipelineName }, initOverrides);
        return await response.value();
    }

    /**
     * Get applications
     */
    async getApplicationsRaw(requestParameters: GetApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApplicationV1Dto>>> {
        const queryParameters: any = {};

        if (requestParameters.teamRefs) {
            queryParameters['teamRefs'] = requestParameters.teamRefs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApplicationV1DtoFromJSON));
    }

    /**
     * Get applications
     */
    async getApplications(teamRefs?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApplicationV1Dto>> {
        const response = await this.getApplicationsRaw({ teamRefs: teamRefs }, initOverrides);
        return await response.value();
    }

    /**
     * Get github repository templates
     */
    async getGithubRepositoryTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GithubRepositoryTemplateV1Dto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/githubrepositorytemplates/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GithubRepositoryTemplateV1DtoFromJSON));
    }

    /**
     * Get github repository templates
     */
    async getGithubRepositoryTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GithubRepositoryTemplateV1Dto>> {
        const response = await this.getGithubRepositoryTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Harbor Project for Application
     */
    async getHarborProjectForApplicationRaw(requestParameters: GetHarborProjectForApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HarborProjectV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getHarborProjectForApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/harborprojects/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HarborProjectV1DtoFromJSON(jsonValue));
    }

    /**
     * Get Harbor Project for Application
     */
    async getHarborProjectForApplication(application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HarborProjectV1Dto> {
        const response = await this.getHarborProjectForApplicationRaw({ application: application }, initOverrides);
        return await response.value();
    }

    /**
     * Get applications
     */
    async getPagedApplicationsRaw(requestParameters: GetPagedApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationsPageDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.teamRefs) {
            queryParameters['teamRefs'] = requestParameters.teamRefs;
        }

        if (requestParameters.applicationNameSearchKey !== undefined) {
            queryParameters['applicationNameSearchKey'] = requestParameters.applicationNameSearchKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationsPageDtoFromJSON(jsonValue));
    }

    /**
     * Get applications
     */
    async getPagedApplications(page?: number, size?: number, teamRefs?: Array<string>, applicationNameSearchKey?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationsPageDto> {
        const response = await this.getPagedApplicationsRaw({ page: page, size: size, teamRefs: teamRefs, applicationNameSearchKey: applicationNameSearchKey }, initOverrides);
        return await response.value();
    }

    /**
     * Get available regions
     */
    async getRegionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/regions/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get available regions
     */
    async getRegions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getRegionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get available repositories
     */
    async getRepositoriesRaw(requestParameters: GetRepositoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepositoryV1Dto>>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling getRepositories.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repositories/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepositoryV1DtoFromJSON));
    }

    /**
     * Get available repositories
     */
    async getRepositories(query: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepositoryV1Dto>> {
        const response = await this.getRepositoriesRaw({ query: query }, initOverrides);
        return await response.value();
    }

    /**
     * Get revision
     */
    async getRevisionRaw(requestParameters: GetRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RevisionV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getRevision.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getRevision.');
        }

        if (requestParameters.revision === null || requestParameters.revision === undefined) {
            throw new runtime.RequiredError('revision','Required parameter requestParameters.revision was null or undefined when calling getRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/v1/{revision}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"revision"}}`, encodeURIComponent(String(requestParameters.revision))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RevisionV1DtoFromJSON(jsonValue));
    }

    /**
     * Get revision
     */
    async getRevision(application: string, environment: string, revision: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RevisionV1Dto> {
        const response = await this.getRevisionRaw({ application: application, environment: environment, revision: revision }, initOverrides);
        return await response.value();
    }

    /**
     * Get revisions
     */
    async getRevisionsRaw(requestParameters: GetRevisionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RevisionV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getRevisions.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getRevisions.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling getRevisions.');
        }

        const queryParameters: any = {};

        if (requestParameters.onlyActive !== undefined) {
            queryParameters['onlyActive'] = requestParameters.onlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/revisions/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RevisionV1DtoFromJSON));
    }

    /**
     * Get revisions
     */
    async getRevisions(application: string, environment: string, service: string, onlyActive?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RevisionV1Dto>> {
        const response = await this.getRevisionsRaw({ application: application, environment: environment, service: service, onlyActive: onlyActive }, initOverrides);
        return await response.value();
    }

    /**
     * Get secret group
     */
    async getSecretGroupRaw(requestParameters: GetSecretGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecretGroupV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getSecretGroup.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getSecretGroup.');
        }

        if (requestParameters.secretGroup === null || requestParameters.secretGroup === undefined) {
            throw new runtime.RequiredError('secretGroup','Required parameter requestParameters.secretGroup was null or undefined when calling getSecretGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/secretgroups/v1/{secretGroup}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"secretGroup"}}`, encodeURIComponent(String(requestParameters.secretGroup))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecretGroupV1DtoFromJSON(jsonValue));
    }

    /**
     * Get secret group
     */
    async getSecretGroup(application: string, environment: string, secretGroup: string, version?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecretGroupV1Dto> {
        const response = await this.getSecretGroupRaw({ application: application, environment: environment, secretGroup: secretGroup, version: version }, initOverrides);
        return await response.value();
    }

    /**
     * Get secret groups
     */
    async getSecretGroupsRaw(requestParameters: GetSecretGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SecretGroupV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getSecretGroups.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getSecretGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/secretgroups/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SecretGroupV1DtoFromJSON));
    }

    /**
     * Get secret groups
     */
    async getSecretGroups(application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SecretGroupV1Dto>> {
        const response = await this.getSecretGroupsRaw({ application: application, environment: environment }, initOverrides);
        return await response.value();
    }

    /**
     * Get service
     */
    async getServiceRaw(requestParameters: GetServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getService.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getService.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling getService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1/{service}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceV1DtoFromJSON(jsonValue));
    }

    /**
     * Get service
     */
    async getService(application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceV1Dto> {
        const response = await this.getServiceRaw({ application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Get a service pipeline
     */
    async getServicePipelineRaw(requestParameters: GetServicePipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServicePipelineV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getServicePipeline.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getServicePipeline.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling getServicePipeline.');
        }

        if (requestParameters.pipelineName === null || requestParameters.pipelineName === undefined) {
            throw new runtime.RequiredError('pipelineName','Required parameter requestParameters.pipelineName was null or undefined when calling getServicePipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/pipelines/v1/{pipelineName}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))).replace(`{${"pipelineName"}}`, encodeURIComponent(String(requestParameters.pipelineName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServicePipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Get a service pipeline
     */
    async getServicePipeline(application: string, environment: string, service: string, pipelineName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServicePipelineV1Dto> {
        const response = await this.getServicePipelineRaw({ application: application, environment: environment, service: service, pipelineName: pipelineName }, initOverrides);
        return await response.value();
    }

    /**
     * Get service pipelines
     */
    async getServicePipelinesRaw(requestParameters: GetServicePipelinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServicePipelinesPageDto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getServicePipelines.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getServicePipelines.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling getServicePipelines.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/pipelines/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServicePipelinesPageDtoFromJSON(jsonValue));
    }

    /**
     * Get service pipelines
     */
    async getServicePipelines(application: string, environment: string, service: string, page?: number, size?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServicePipelinesPageDto> {
        const response = await this.getServicePipelinesRaw({ application: application, environment: environment, service: service, page: page, size: size }, initOverrides);
        return await response.value();
    }

    /**
     * Get a service quickstart pipeline
     */
    async getServiceQuickstartPipelineRaw(requestParameters: GetServiceQuickstartPipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceQuickstartPipelineV1Dto>> {
        if (requestParameters.pipelineName === null || requestParameters.pipelineName === undefined) {
            throw new runtime.RequiredError('pipelineName','Required parameter requestParameters.pipelineName was null or undefined when calling getServiceQuickstartPipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/servicequickstartpipelines/v1/{pipelineName}`.replace(`{${"pipelineName"}}`, encodeURIComponent(String(requestParameters.pipelineName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceQuickstartPipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Get a service quickstart pipeline
     */
    async getServiceQuickstartPipeline(pipelineName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceQuickstartPipelineV1Dto> {
        const response = await this.getServiceQuickstartPipelineRaw({ pipelineName: pipelineName }, initOverrides);
        return await response.value();
    }

    /**
     * Get all tags for given service. Only supported storage is Maersk Harbor (https://harbor.maersk.io/).
     */
    async getServiceTagsRaw(requestParameters: GetServiceTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceTagV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getServiceTags.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getServiceTags.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling getServiceTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/tags/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceTagV1DtoFromJSON));
    }

    /**
     * Get all tags for given service. Only supported storage is Maersk Harbor (https://harbor.maersk.io/).
     */
    async getServiceTags(application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceTagV1Dto>> {
        const response = await this.getServiceTagsRaw({ application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Get services
     */
    async getServicesRaw(requestParameters: GetServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getServices.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getServices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceV1DtoFromJSON));
    }

    /**
     * Get services
     */
    async getServices(application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceV1Dto>> {
        const response = await this.getServicesRaw({ application: application, environment: environment }, initOverrides);
        return await response.value();
    }

    /**
     * Patch service
     */
    async patchServiceRaw(requestParameters: PatchServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceV1Dto>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling patchService.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling patchService.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling patchService.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling patchService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1/{service}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceV1DtoFromJSON(jsonValue));
    }

    /**
     * Patch service
     */
    async patchService(requestBody: { [key: string]: object | undefined; }, application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceV1Dto> {
        const response = await this.patchServiceRaw({ requestBody: requestBody, application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Update harbor project. This operation is asynchronous. Returned object contains the \'status\' field that can be used to track the operation progress via polling the corresponding \'GET\' endpoint. 
     */
    async updateHarborProjectRaw(requestParameters: UpdateHarborProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.harborProjectV1Dto === null || requestParameters.harborProjectV1Dto === undefined) {
            throw new runtime.RequiredError('harborProjectV1Dto','Required parameter requestParameters.harborProjectV1Dto was null or undefined when calling updateHarborProject.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateHarborProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/harborprojects/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HarborProjectV1DtoToJSON(requestParameters.harborProjectV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update harbor project. This operation is asynchronous. Returned object contains the \'status\' field that can be used to track the operation progress via polling the corresponding \'GET\' endpoint. 
     */
    async updateHarborProject(harborProjectV1Dto: HarborProjectV1Dto, application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateHarborProjectRaw({ harborProjectV1Dto: harborProjectV1Dto, application: application }, initOverrides);
    }

    /**
     * Update infra broker registration
     */
    async updateInfraBrokerRaw(requestParameters: UpdateInfraBrokerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfraBrokerV1Dto>> {
        if (requestParameters.infraBrokerV1Dto === null || requestParameters.infraBrokerV1Dto === undefined) {
            throw new runtime.RequiredError('infraBrokerV1Dto','Required parameter requestParameters.infraBrokerV1Dto was null or undefined when calling updateInfraBroker.');
        }

        if (requestParameters.infrabroker === null || requestParameters.infrabroker === undefined) {
            throw new runtime.RequiredError('infrabroker','Required parameter requestParameters.infrabroker was null or undefined when calling updateInfraBroker.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/infrabrokers/v1/{infrabroker}`.replace(`{${"infrabroker"}}`, encodeURIComponent(String(requestParameters.infrabroker))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfraBrokerV1DtoToJSON(requestParameters.infraBrokerV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfraBrokerV1DtoFromJSON(jsonValue));
    }

    /**
     * Update infra broker registration
     */
    async updateInfraBroker(infraBrokerV1Dto: InfraBrokerV1Dto, infrabroker: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfraBrokerV1Dto> {
        const response = await this.updateInfraBrokerRaw({ infraBrokerV1Dto: infraBrokerV1Dto, infrabroker: infrabroker }, initOverrides);
        return await response.value();
    }

    /**
     * Update revision
     */
    async updateRevisionRaw(requestParameters: UpdateRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateRevision.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateRevision.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateRevision.');
        }

        if (requestParameters.revision === null || requestParameters.revision === undefined) {
            throw new runtime.RequiredError('revision','Required parameter requestParameters.revision was null or undefined when calling updateRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/v1/{revision}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"revision"}}`, encodeURIComponent(String(requestParameters.revision))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update revision
     */
    async updateRevision(requestBody: { [key: string]: object | undefined; }, application: string, environment: string, revision: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRevisionRaw({ requestBody: requestBody, application: application, environment: environment, revision: revision }, initOverrides);
    }

    /**
     * Set enabled flag on a server-group
     */
    async updateRevisionEnabledRaw(requestParameters: UpdateRevisionEnabledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateRevisionEnabled.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateRevisionEnabled.');
        }

        if (requestParameters.revision === null || requestParameters.revision === undefined) {
            throw new runtime.RequiredError('revision','Required parameter requestParameters.revision was null or undefined when calling updateRevisionEnabled.');
        }

        if (requestParameters.enabled === null || requestParameters.enabled === undefined) {
            throw new runtime.RequiredError('enabled','Required parameter requestParameters.enabled was null or undefined when calling updateRevisionEnabled.');
        }

        const queryParameters: any = {};

        if (requestParameters.enabled !== undefined) {
            queryParameters['enabled'] = requestParameters.enabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/v1/{revision}/enabled`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"revision"}}`, encodeURIComponent(String(requestParameters.revision))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set enabled flag on a server-group
     */
    async updateRevisionEnabled(application: string, environment: string, revision: string, enabled: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRevisionEnabledRaw({ application: application, environment: environment, revision: revision, enabled: enabled }, initOverrides);
    }

    /**
     * Update secret group
     */
    async updateSecretGroupRaw(requestParameters: UpdateSecretGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecretGroupV1Dto>> {
        if (requestParameters.secretGroupV1Dto === null || requestParameters.secretGroupV1Dto === undefined) {
            throw new runtime.RequiredError('secretGroupV1Dto','Required parameter requestParameters.secretGroupV1Dto was null or undefined when calling updateSecretGroup.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateSecretGroup.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateSecretGroup.');
        }

        if (requestParameters.secretGroup === null || requestParameters.secretGroup === undefined) {
            throw new runtime.RequiredError('secretGroup','Required parameter requestParameters.secretGroup was null or undefined when calling updateSecretGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/secretgroups/v1/{secretGroup}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"secretGroup"}}`, encodeURIComponent(String(requestParameters.secretGroup))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SecretGroupV1DtoToJSON(requestParameters.secretGroupV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecretGroupV1DtoFromJSON(jsonValue));
    }

    /**
     * Update secret group
     */
    async updateSecretGroup(secretGroupV1Dto: SecretGroupV1Dto, application: string, environment: string, secretGroup: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecretGroupV1Dto> {
        const response = await this.updateSecretGroupRaw({ secretGroupV1Dto: secretGroupV1Dto, application: application, environment: environment, secretGroup: secretGroup }, initOverrides);
        return await response.value();
    }

    /**
     * Update service
     */
    async updateServiceRaw(requestParameters: UpdateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceV1Dto>> {
        if (requestParameters.serviceV1Dto === null || requestParameters.serviceV1Dto === undefined) {
            throw new runtime.RequiredError('serviceV1Dto','Required parameter requestParameters.serviceV1Dto was null or undefined when calling updateService.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateService.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateService.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling updateService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1/{service}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceV1DtoToJSON(requestParameters.serviceV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceV1DtoFromJSON(jsonValue));
    }

    /**
     * Update service
     */
    async updateService(serviceV1Dto: ServiceV1Dto, application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceV1Dto> {
        const response = await this.updateServiceRaw({ serviceV1Dto: serviceV1Dto, application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

}
