/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ScalingTypeSpecV1Dto } from './ScalingTypeSpecV1Dto';
import {
    ScalingTypeSpecV1DtoFromJSON,
    ScalingTypeSpecV1DtoFromJSONTyped,
    ScalingTypeSpecV1DtoToJSON,
} from './ScalingTypeSpecV1Dto';

/**
 * 
 * @export
 * @interface CPUBasedScalingSpecV1Dto
 */
export interface CPUBasedScalingSpecV1Dto {
    /**
     * 
     * @type {ScalingTypeSpecV1Dto}
     * @memberof CPUBasedScalingSpecV1Dto
     */
    scalingType: ScalingTypeSpecV1Dto;
    /**
     * 
     * @type {number}
     * @memberof CPUBasedScalingSpecV1Dto
     */
    readonly count?: number;
    /**
     * 
     * @type {number}
     * @memberof CPUBasedScalingSpecV1Dto
     */
    minReplicas: number;
    /**
     * 
     * @type {number}
     * @memberof CPUBasedScalingSpecV1Dto
     */
    maxReplicas: number;
    /**
     * 
     * @type {number}
     * @memberof CPUBasedScalingSpecV1Dto
     */
    cpuUtilization: number;
}

/**
 * Check if a given object implements the CPUBasedScalingSpecV1Dto interface.
 */
export function instanceOfCPUBasedScalingSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scalingType" in value;
    isInstance = isInstance && "minReplicas" in value;
    isInstance = isInstance && "maxReplicas" in value;
    isInstance = isInstance && "cpuUtilization" in value;

    return isInstance;
}

export function CPUBasedScalingSpecV1DtoFromJSON(json: any): CPUBasedScalingSpecV1Dto {
    return CPUBasedScalingSpecV1DtoFromJSONTyped(json, false);
}

export function CPUBasedScalingSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CPUBasedScalingSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scalingType': ScalingTypeSpecV1DtoFromJSON(json['scalingType']),
        'count': !exists(json, 'count') ? undefined : json['count'],
        'minReplicas': json['minReplicas'],
        'maxReplicas': json['maxReplicas'],
        'cpuUtilization': json['cpuUtilization'],
    };
}

export function CPUBasedScalingSpecV1DtoToJSON(value?: CPUBasedScalingSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scalingType': ScalingTypeSpecV1DtoToJSON(value.scalingType),
        'minReplicas': value.minReplicas,
        'maxReplicas': value.maxReplicas,
        'cpuUtilization': value.cpuUtilization,
    };
}

